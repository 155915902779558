.custom-button {
  background: #AD88C6;
  border-radius: 6px !important;
  width: 100%;
  /* width:100%; */
  height: 42px;
  color: #ffffff;
  font-weight: 700;
  letter-spacing: 0.2em;
  border: none;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Work Sans", sans-serif;
}
.custom-button:hover {
  background: #7469B6;
  color: white;
}
