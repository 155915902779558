.wrap-bussiness-fields {
    padding: 20px 30px 32px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
    align-items: center;
}

.bussiness-btn-wrap {
    margin-left: auto;
    margin-right: auto;
}
.user-d-listing {
    display: flex;
    justify-content: center;
    padding: 20px 20px;
    text-align: center;
    flex-direction: column;
    gap: 10px;
    position: relative;
}
.user-d-listing h2 {
    font-size: 2em;
    color: #021520;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.user-d-listing h3 {
    display: flex;
    font-weight: 400;
    font-size: 1.1rem;
    justify-content: center;
    gap: 6px;
    align-items: center;
}
.user-d-listing h4{
    display: flex;
    font-size: .9rem;
    font-weight: 300;
    color: #AD88C6;
    justify-content: center;
    gap: 6px;
    align-items: center;
}
.user-d-listing h5 {
    display: flex;
    font-size: .9rem;
    font-weight: 300;
    color: #009c7a;
    justify-content: center;
    gap: 6px;
    align-items: center;
}

.bussiness-input-wrap{
    margin-top: 10px;
}